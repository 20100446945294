import React from "react";

import ButtonSolid from "../../components/Button/ButtonSolid";
// import ButtonGhost from "../../components/Button/ButtonGhost";

import PortableText from "../../components/Blog/portableText";
import tw from "twin.macro";
import styled from "@emotion/styled";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-2 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const NeighborhoodSection2 = ({
  headingLevel,
  className,
  title1,
  description1,
  title2,
  description2,
  title3,
  description3,
  title4,
  description4,
  title5,
  description5,
}) => {
  return (
    <>
      <section className={` ${className || ""}`}>
        <div className="container">
          <div className="grid gap-y-10 md:gap-x-12 lg:gap-x-24">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-12 md:mb-16">
                <StyledContent>
                  <h2> A Great Place to Live</h2>
                  <p>
                    Bankers Hill is an uptown neighborhood near Balboa Park.
                    It’s mainly residential and many of the homes, which date
                    back to the late 1800s, were designed by notable architects.
                    The community sits on a hill overlooking San Diego Bay and
                    is centrally located to downtown and the bay, Coronado, and
                    the communities of Hillcrest and Mission Hills.
                  </p>
                </StyledContent>
              </div>

              <div className="mb-12 md:mb-16">
                <h3>The Rich History of Bankers Hill</h3>
                <p>
                  Bankers Hill was first developed in the 1890s and got its name
                  because the hillside attracted San Diego’s wealthy families,
                  who hired top architects to design their homes. Today, many of
                  the homes, including the one that houses our San Diego senior
                  assisted living community and was originally built in 1927,
                  have been lovingly restored.
                </p>
              </div>

              <div className="mb-12 md:mb-16">
                <StyledContent>
                  <h3>Things to Do in the Neighborhood</h3>
                  <p>
                    There are plenty of things to see and do for residents and
                    their families. You can:
                  </p>
                  <div className="md:col-end-4 md:pr pl-0 pr-[15px] ">
                    {/* left list */}

                    <ul className="grid gap-y-3 md:gap-y-[5px] pl-0">
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <span>
                          Take a stroll around the neighborhood to see the
                          various architectural-styled homes
                        </span>
                      </li>
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <span>
                          Spend time in nearby Balboa Park, San Diego’s crown
                          jewel
                        </span>
                      </li>
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <span>
                          See the animals at the San Diego Zoo, located within
                          Balboa Park
                        </span>
                      </li>
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <span>
                          Visit the USS Midway Museum, the city’s historic naval
                          aircraft carrier
                        </span>
                      </li>
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <span>
                          Catch a show at The Old Globe performing arts theater
                        </span>
                      </li>
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-[#822824] stroke-[#F8F2F2] stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <span>
                          Have some great Italian food in Little Italy
                        </span>
                      </li>
                    </ul>
                    <p>
                      {" "}
                      You can also hop over the bay to Coronado to tour the
                      historic Hotel del Coronado, eat at great local
                      restaurants, and find unique items at the city’s quaint
                      shops.
                    </p>
                  </div>
                </StyledContent>
                <ButtonSolid
                  className="w-[140px]"
                  modal="modal-contact"
                  text="Contact"
                />
              </div>
            </div>

            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <div className="md: bg-tertiary-50 sticky top-32 rounded-3xl p-10 md:rounded-3xl md:bg-secondary-400 md:p-10">
                <div>
                  {title5 && <h3>{title5}</h3>}
                  <StyledContent>
                    {description5 && <PortableText blocks={description5} />}
                  </StyledContent>
                  <ButtonSolid modal="modal-contact" text="Book a Visit" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NeighborhoodSection2;
