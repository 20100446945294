import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ButtonWithUnderline from "../Button/ButtonWithUnderline";
const About = ({ headingLevel, className, hide }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section
      className={` ${className || "mb-14 p-4 md:mb-24 "}`}
    >
      <div className="container">
        <header className="mb-12 md:mb-14">
          <HeadingTag className="font-medium">
            The HarborView Lifestyle
          </HeadingTag>
        </header>
        <div className="grid gap-y-10 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
          {hide !== 1 && (
            <Link
              to="/amenities/"
              className="group flex flex-col  font-normal text-typography-body no-underline hover:text-typography-body"
            >
              <div className="overflow-hidden rounded-3xl">
                <StaticImage
                  src="../../images/0.0 Repeated Modules/HarborView Lifestyle/Staff.jpg"
                  loading="lazy"
                  width={746}
                  className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                />
              </div>
              <div className="pt-6">
                <h3 className="heading-three">Our Staff</h3>
                <p className="mb-0">
                  We hire the best in the industry. Our caregivers are
                  well-trained and care for residents as if they were their own
                  family.
                </p>
                <ButtonWithUnderline
                  text="learn more"
                  className="mr-auto max-w-[180px] pt-2 text-[16px] font-normal lg:ml-0"
                />
              </div>
            </Link>
          )}

          {hide !== 2 && (
            <Link
              to="/amenities/"
              className="group flex flex-col  font-normal text-typography-body no-underline hover:text-typography-body"
            >
              <div className="overflow-hidden rounded-3xl">
                <StaticImage
                  src="../../images/0.0 Repeated Modules/HarborView Lifestyle/Amenities.jpg"
                  loading="lazy"
                  width={746}
                  className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                />
              </div>
              <div className="pt-6">
                <h3 className="heading-three">The Amenities</h3>
                <p className="mb-0">
                  HarborView has a variety of amenities—from personal laundry
                  and daily housekeeping to daily physical fitness activities.
  
                </p>
                <ButtonWithUnderline
                  text="learn more"
                  className="mr-auto max-w-[180px] pt-2 text-[16px] font-normal lg:ml-0"
                />
              </div>
            </Link>
          )}

          {hide !== 3 && (
            <Link
              to="/neighborhood/"
              className="group flex flex-col  font-normal text-typography-body no-underline hover:text-typography-body"
            >
              <div className="overflow-hidden rounded-3xl">
                <StaticImage
                  src="../../images/0.0 Repeated Modules/HarborView Lifestyle/Neighborhood.jpg"
                  loading="lazy"
                  width={746}
                  className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                />
              </div>
              <div className="pt-6">
                <h3 className="heading-three">The Neighborhood</h3>
                <p className="mb-0">
                  We’re located in the historic neighborhood of Bankers Hill
                  overlooking the downtown skyline and San Diego Bay.
                </p>
                <ButtonWithUnderline
                  text="learn more"
                  className="mr-auto max-w-[180px] pt-2 text-[16px] font-normal lg:ml-0"
                />
              </div>
            </Link>
          )}

          {/* {hide !== 4 && (
            <Link
              to=""
              className="group flex flex-col  font-normal text-typography-body no-underline hover:text-typography-body"
            >
              <div className="overflow-hidden rounded-3xl">
                <StaticImage
                  src="../../images/0.0 Repeated Modules/HarborView Lifestyle/FAQs.jpg"
                  loading="lazy"
                  width={746}
                  className="w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                />
              </div>
              <div className="pt-6">
                <h3 className="heading-three">FAQs</h3>
                <p className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
                  aliquam, purus sit amet luctus venenatis, lectus magna
                  fringilla urna, porttitor
                </p>
              </div>
            </Link>
          )} */}
        </div>
      </div>
    </section>
  );
};

export default About;
